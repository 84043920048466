@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.s-uniq-meth {
    overflow: hidden;

    &_laguna {
        position: relative;
        overflow: visible;

        &::before {
            content: '';

            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            transform: skewY(-7deg);
            
            background-color: $color-laguna-light;
        }

        .s-uniq-meth__container {
            overflow: hidden;
        }
    }
}
.s-uniq-meth__container {
    padding-bottom: 120px;

    position: relative;
    z-index: 1;

    @media (min-width: $small-size) {
        display: flex;
        flex-direction: column;

        padding: {
            top: 100px;
            bottom: 0;
        };
    }
    @media (min-width: $medium-size) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 32px;
    }
    @media (min-width: $large-size) {
        grid-column-gap: 80px;
    }
    @media (min-width: $largest-size) {
        grid-template-columns: 1fr 1.2fr;
    }

    &_middle-aligned {
        @media (min-width: $medium-size) {
            align-items: center;
        }
    }
    &_big-paddings {
        @media (min-width: $medium-size) {
            padding-top: 120px;
            padding-bottom: 120px;
        }
    }
}

.s-uniq-meth__heading {
    max-width: 600px;
    margin-bottom: 20px;
}

.s-uniq-meth__heading-italic {
    margin-left: 4px;

    @media (min-width: $small-size) {
        margin-left: 12px;
    }
}

.s-uniq-meth__wr-text {
    @media (min-width: $small-size) {
        max-width: 560px;
    }
}
.s-uniq-meth__text {
    &:not(:last-child) {
        margin-bottom: 24px;
    }
}

.s-uniq-meth__col-text {
    margin-bottom: 30px;

    @media (min-width: $small-size) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    @media (min-width: $medium-size) {
        margin-bottom: 0;
    }
    @media (min-width: $large-size) {
        padding-top: 20px;
    }
}

.s-uniq-meth__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 40px;
    right: 20px;
    left: 20px;

    border-color: $color-orange-dark;

    background-color: $color-orange-dark;

    &:hover {
        @media (hover: hover) {
            color: $color-orange-dark;
        }
    }

    @media (min-width: $smallest-size) {
        left: 32px;
        right: auto;
    }
    @media (min-width: $small-size) {
        margin-top: 40px;

        position: static;
        right: auto;
        bottom: auto;
        left: auto;
    }
}
.s-uniq-meth__btn span {
    margin-left: 10px;

    font-weight: 400;
}
@media (hover: hover) {
    .s-uniq-meth__btn:hover span::before {
        color: $color-orange-dark;
    }
}

.s-uniq-meth__col-wheel {
    width: 100%;
    max-width: 700px;

    @include img-wrap;
    @include aspect-ratio-w-pseudo(1);

    img,
    svg {
        overflow: visible;
        object-fit: contain;
    }

    @media (min-width: $small-size) {
        margin-left: auto;
    }
    @media (min-width: $medium-size) {
        margin-left: 0;
    }
}


.meth-wheel,
.meth-wheel-ajax {
    width: 100%;
    
    @include img-wrap;
    @include aspect-ratio-w-pseudo(1);

    user-select: none;

    overflow: visible;

    img,
    svg {
        overflow: visible;
        object-fit: contain;
    }
}
.meth-wheel__text {
    transform: scale(1);
    transform-origin: 50% 0%;
    transform-box: fill-box;

    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: .04em;
    fill: #ffffff;

    transition: 300ms ease;

    &_black {
        font-weight: 600;
        fill: #676767;
    }
    &_small {
        font-size: 13px;
    }
}
.meth-wheel__footer-ico,
.meth-wheel__ico {
    transform-box: fill-box;

    transition: 300ms ease;
}
.meth-wheel__footer-ico {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
}
.meth-wheel__ico {
    transform: scale(1);
    transform-origin: 50% 100%;
}
.meth-wheel__btn {
    &:hover {
        .meth-wheel__ico {
            @media (hover: hover) {
                transform: scale(1.2);
            }
        }
        .meth-wheel__text {
            @media (hover: hover) {
                transform: scale(1.1);
            }
        }
    }
    &_1,
    &_3 {
        &:hover {
            .meth-wheel__background {
                @media (hover: hover) {
                    filter: blur(2px) grayscale(10%) hue-rotate(45deg);
                }
            }
        }
    }
    &_2,
    &_4 {
        &:hover {
            .meth-wheel__background {
                @media (hover: hover) {
                    filter: blur(2px) grayscale(10%) hue-rotate(180deg);
                }
            }
        }
    }
}
.meth-wheel__footer-btn {
    &:hover {
        .meth-wheel__footer-ico {
            @media (hover: hover) {
                transform: rotate(360deg);
            }
        }
        .meth-wheel__text {
            @media (hover: hover) {
                transform: translateY(-1px) scale(1.05);
            }
        }
    }
}

// wheel animation
$wheel-rotate-timing: 1500ms;
$wheel-step-timing: 350ms;
.meth-wheel__wheel {
    transform: rotate(540deg);
    transform-origin: center center;
    transform-box: fill-box;

    transition: $wheel-rotate-timing cubic-bezier(0.65, 0, 0.35, 1);
}
.meth-wheel__sub-text,
.meth-wheel__arrow,
.meth-wheel__btn-content {
    transition-duration: $wheel-step-timing;
    transition-timing-function: ease;

    opacity: 0;
}
.meth-wheel__arrow {
    transform-origin: center center;
    transform-box: fill-box;

    &_top-right {
        transform: translateX(50%);
    }
    &_bottom-left {
        transform: translateX(-50%);
    }
}
#wheel-animation-part {
    @for $count from 1 to 9 {
        &_#{$count} {
            transition-delay: $wheel-rotate-timing + $wheel-step-timing * ($count - 1);
        }
    }
}
.meth-wheel,
.meth-wheel-ajax {
    position: relative;

    &::after {
        content: '';

        display: block;

        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
    }

    &.init {
        .meth-wheel__wheel {
            transform: rotate(0);
        }
        .meth-wheel__sub-text,
        .meth-wheel__arrow,
        .meth-wheel__btn-content {
            opacity: 1;
        }
        .meth-wheel__arrow {
            transform: translateX(0%);
        }
    }
    &.active {
        &::after {
            display: none;
        }
    }
}

.uniq-meth__link {
    margin-top: 30px;

    @media (min-width: $small-mid-size) {
        margin-top: 40px;
    }
}
.uniq-meth__wr-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
    margin-top: 40px;
}